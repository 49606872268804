'use client';
import {
  CloseIcon,
  HeartIcon,
  LogoIcon,
  MenuIcon,
  PersonOutlineIcon,
} from '@c/icons';
import { NotificationButton } from '@features/notifications';
import { UserIcon } from '@heroicons/react/20/solid';
import Button from '@ui/Button';
import SearchBox from '@ui/SearchBox';
import { isLargeDesktop } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Suspense, useState } from 'react';
import MenuDrawer from './drawers/MenuDrawer';
import ChatButton from './icons/buttons/ChatButton';
import ShoppingCartButton from './icons/buttons/ShoppingCartButton';
import UserMenu from './menus/UserMenu';
import CategoryBar from './navigation/CategoryBar';

const Navbar = () => {
  const { user, userDoc } = useAuth();
  const { showMobileSearchbar, setShowMobileSearchbar, searchTerm } =
    useSearch();

  const pathname = usePathname();

  const [searchboxFocused, setSearchboxFocused] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <MenuDrawer show={showDrawer} dismiss={() => setShowDrawer(false)} />
      <div className="sticky top-0 z-50 w-full bg-brand-white">
        {/* Top bar */}
        <div className="top-0 z-40  flex h-[12rem] w-full items-center justify-between bg-brand-white sm:mx-auto sm:max-w-content lg:h-[8rem] lg:gap-[1.6rem]">
          {/* Left nav items */}
          {/* desktop */}
          <div className="mx-auto hidden max-w-content grow gap-[2.4rem] lg:flex">
            <Link href="/" className="flex items-center">
              <LogoIcon variant="default" width={180} />
            </Link>
            <Suspense>
              <SearchBox
                onBlur={() => setSearchboxFocused(false)}
                onFocus={() => setSearchboxFocused(true)}
                dismissOnBlur={true}
              />
            </Suspense>
          </div>

          {/* mobile */}
          <div className="relative flex w-full flex-col items-center justify-between pl-[0.8rem] lg:hidden">
            <div className="flex w-full justify-between">
              <button
                type="button"
                aria-label="menu-trigger"
                onClick={() => setShowDrawer(true)}
                className="mr-[1.6rem] text-brand-black lg:hidden lg:w-fit"
              >
                {showDrawer ? <CloseIcon /> : <MenuIcon />}
              </button>
              <Link
                aria-label="link to home"
                href="/"
                className="flex items-center justify-start lg:w-1/3"
              >
                <LogoIcon variant="default" width={150} height={32} />
              </Link>

              <div className="ml-[1.6rem] flex grow items-center justify-end gap-[1.6rem] lg:w-fit lg:gap-[2rem]">
                <div className="flex items-center">
                  {!user ? (
                    <Button
                      type="text"
                      width="small"
                      href="/login"
                      ariaText="login"
                      leadingIcon={<PersonOutlineIcon />}
                    />
                  ) : !showMobileSearchbar ? (
                    <div className="flex items-center gap-x-4">
                      <NotificationButton />
                      <UserMenu />
                      <div className="-ml-2 -mt-1">
                        <ShoppingCartButton displayCount={true} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <Suspense>
              <SearchBox
                onMobileFocus={() => setSearchboxFocused(true)}
                dismissOnBlur
                showCloseButton={!!searchTerm}
                onClearClick={() => setShowMobileSearchbar(false)}
                onEnter={() => setShowMobileSearchbar(false)}
              />
            </Suspense>
          </div>
          {/* end left nav items */}

          {/* Right nav items */}
          {(!searchboxFocused || isLargeDesktop()) && (
            <div className="flex items-center gap-[0.4rem] font-medium text-brand-black lg:gap-[1.2rem]">
              {user ? (
                <div className="hidden gap-[0.8rem] lg:flex">
                  <div className="order-3">
                    <ShoppingCartButton displayCount={true} />
                  </div>
                  {/* favorites */}
                  <div className="flex gap-x-4">
                    <Link
                      href="/dashboard/favorites"
                      className="w-full min-w-[4.4rem] p-4 text-brand-black transition-colors hover:text-brand-primary"
                      aria-label="/dashboard/favorites"
                    >
                      <HeartIcon />
                    </Link>

                    <NotificationButton />

                    <ChatButton isSupport={!!userDoc?.roles?.support} />

                    <div className="mb-2 ml-2 flex items-center justify-center">
                      <UserMenu />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="hidden gap-[0.8rem] lg:flex">
                    <Button
                      href={{
                        pathname: '/signup',
                        ...(pathname !== '/' && {
                          query: {
                            redirect: pathname,
                          },
                        }),
                      }}
                      type="text"
                      text="Sign up"
                      width="smaller"
                    />
                    <Button
                      href={{
                        pathname: '/login',
                        ...(pathname !== '/' && {
                          query: {
                            redirect: pathname,
                          },
                        }),
                      }}
                      type="text"
                      text="Login"
                      width="smaller"
                    />
                    <ShoppingCartButton displayCount={true} />
                  </div>
                  <div className="lg:hidden">
                    <UserIcon />
                  </div>
                </>
              )}
            </div>
          )}

          <div className="hidden items-center justify-center font-extrabold tracking-wider lg:flex">
            <Button
              text="SELL"
              type="secondary"
              width={'normal'}
              href={
                userDoc?.account_activated ? '/create-listing' : '/how-it-works'
              }
              height="small"
            />
          </div>
        </div>

        <div className="h-px w-full bg-brand-lightest-gray" />

        <CategoryBar />

        <div className="h-px w-full bg-brand-lightest-gray" />
      </div>
    </>
  );
};

export default Navbar;
